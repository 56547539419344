import React from 'react';
import FounderImage from '../../images/founder-image.png';
import FounderImageMobile from '../../images/founder-mobile-image.png';

const FounderComponent = () => {
  return (
    <div className='founder-section'>
      <div className='row align-items-center'>
        <div className='col-md-6'>
          <div className='image-wrapper'>
            <p className=' founder-section__title-mobile'>
              Our Founder's Mission: To Help You
            </p>
            <img src={FounderImage} className='founder-section__image' />
            <img
              src={FounderImageMobile}
              className='founder-section__image-mobile'
            />
          </div>
        </div>
        <div className='col-md-6 founder-section__content'>
          <p className=' founder-section__content-title'>
            Our Founder's Mission: To Help You
          </p>
          <p className=' founder-section__content-description'>
            JD Conway has been empowering the humanization of human capital for
            almost 20 years. As the former Head of Talent at BambooHR, JD
            actively innovated the candidate experience at scale, as BambooHR
            grew nearly 10x over during his tenure. Due to the high demand for
            his approach to Talent Attraction and Retention, JD founded
            TRACTalent to advise and execute strategies to help small businesses
            thrive in this competitive talent market.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FounderComponent;
