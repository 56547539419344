import React from 'react';
import B from '../images/b-logo.png';
import Forbes from '../images/forbes-logo.png';
import HR from '../images/hr-logo.png';
import Utah from '../images/utah-logo.png';
import { logosSize } from '../utilities/logosSize';

const logos = [
  { logo: Utah, title: 'utah' },
  { logo: Forbes, title: 'forbes' },
  { logo: B, title: 'b' },
  { logo: HR, title: 'hr' },
];

const LogosComponent = () => {
  return (
    <div className='home-section__logos'>
      {logos.map((item, i) => (
        <img
          key={i}
          src={item.logo}
          alt='logo'
          style={{ maxWidth: logosSize[item.title], width: '100%' }}
        />
      ))}
    </div>
  );
};

export default LogosComponent;
