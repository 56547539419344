import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';

const Contact = ({ setModalOpen, modalOpen }) => {
  const [loading, setLoading] = useState(false);
  const [thankyou, setThankyou] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const form = useRef();

  const onSubmit = data => {
    setLoading(true);

    try {
      emailjs
        .sendForm(
          'service_y8651vr',
          'template_mwgfwig',
          form.current,
          'W8SwysyO7wtjuIkfE'
        )
        .then(res => {
          setLoading(false);
          setThankyou(true);
        })
        .catch(e => {
          console.log(e);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div
      className='contact-modal'
      onClick={() => {
        setThankyou(false);
        setModalOpen(false);
      }}
    >
      {!thankyou ? (
        <div
          className='contact-modal__wrapper'
          onClick={e => e.stopPropagation()}
        >
          <>
            <div
              className='contact-modal__wrapper-x'
              onClick={() => {
                setThankyou(false);
                setModalOpen(!modalOpen);
              }}
            >
              <FontAwesomeIcon icon={faX} />
            </div>
            <p className='contact-modal__wrapper-title'>Drop us a line</p>
            <p className='contact-modal__wrapper-description'>
              We’ll get back to you within 48 hours.
            </p>

            <form
              onSubmit={handleSubmit(onSubmit)}
              ref={el => (form.current = el)}
              className='contact-modal__wrapper-form'
              onChange={() => console.log(getValues())}
            >
              <input
                type='text'
                placeholder='First Name*'
                {...register('firstname', {
                  required: true,
                })}
                className='contact-modal__wrapper-form-input'
                style={{
                  borderColor:
                    errors.firstname?.type === 'required'
                      ? '#CC1570'
                      : 'initial',
                }}
              />

              <input
                type='text'
                placeholder='Last Name*'
                {...register('lastname', {
                  required: true,
                })}
                className='contact-modal__wrapper-form-input'
                style={{
                  borderColor:
                    errors.lastname?.type === 'required'
                      ? '#CC1570'
                      : 'initial',
                }}
              />
              <input
                type='email'
                placeholder='email address*'
                {...register('email', {
                  required: true,
                })}
                className='contact-modal__wrapper-form-input'
                style={{
                  borderColor:
                    errors.email?.type === 'required' ? '#CC1570' : 'initial',
                }}
              />
              <input
                type='number'
                placeholder='Phone Number*'
                {...register('number', {
                  required: true,
                })}
                className='contact-modal__wrapper-form-input'
                style={{
                  borderColor:
                    errors.number?.type === 'required' ? '#CC1570' : 'initial',
                }}
              />
              <input
                type='text'
                placeholder='Company Name*'
                {...register('companyName', {
                  required: true,
                })}
                className='contact-modal__wrapper-form-input'
                style={{
                  borderColor:
                    errors.companyName?.type === 'required'
                      ? '#CC1570'
                      : 'initial',
                }}
              />
              <input
                type='number'
                placeholder='Company Size*'
                {...register('compnaySize', {
                  required: true,
                })}
                className='contact-modal__wrapper-form-input'
                style={{
                  borderColor:
                    errors.compnaySize?.type === 'required'
                      ? '#CC1570'
                      : 'initial',
                }}
              />
              <button
                className='contact-modal__wrapper-form-button'
                disabled={loading ? true : false}
              >
                {loading ? 'Sending...' : 'Submit'}
              </button>
            </form>
          </>
        </div>
      ) : (
        <div
          style={{
            height: 'fit-content',
          }}
          className='contact-modal__wrapper'
        >
          <div
            className='contact-modal__wrapper-x'
            onClick={() => {
              setThankyou(false);
              setModalOpen(!modalOpen);
            }}
          >
            <FontAwesomeIcon icon={faX} />
          </div>
          <p className='contact-modal__wrapper-title'>Thank you</p>
          <p className='contact-modal__wrapper-description'>
            We’ll get back to you within 48 hours.
          </p>
        </div>
      )}
    </div>
  );
};

export default Contact;
