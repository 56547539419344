import React from 'react';

const Footer = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '26px 10px',
      }}
    >
      <p
        style={{
          fontWeight: '700',
          fontSize: '16px',
          lineHeight: '19px',
          letterSpacing: '0.2em',
          textTransform: 'uppercase',
          textAlign: 'center',
        }}
      >
        ©trac talent 2023 All rights reserved
      </p>
    </div>
  );
};

export default Footer;
