import React from 'react';
import Icon from '../../images/correct-icon.svg';
import LogosComponent from '../LogosComponent';

const OurProcess = () => {
  return (
    <section className='our-process'>
      <div className='full-container'>
        <div className='our-process__wrapper'>
          <div className='row justify-content-evenly'>
            <div className='col-md-6 our-process__column our-process__column--left'>
              <div className='our-process-logos'>
                <LogosComponent />
              </div>
              <p className='our-process__column-title'>
                Our process &amp; philosophy
              </p>
              <div className='our-process__item'>
                <img src={Icon} alt='icon' className='our-process__item-icon' />
                <p className='our-process__item-text'>
                  A Foundation of Understanding
                </p>
              </div>
              <div className='our-process__item'>
                <img src={Icon} alt='icon' className='our-process__item-icon' />
                <p className='our-process__item-text'>
                  Whole Company Hiring &amp; Onboarding
                </p>
              </div>
              <div className='our-process__item'>
                <img src={Icon} alt='icon' className='our-process__item-icon' />
                <p className='our-process__item-text'>
                  Engagement is Everything
                </p>
              </div>
            </div>
            <div className='col-md-6 our-process__column our-process__column--right'>
              <p className='our-process__column-title'>
                Driven by the simple yet powerful principle of treating people
                like people.
              </p>
              <p className='our-process__description'>
                Hiring methodology is a snake oil market focused on vanity
                metrics designed to impress executives and sell services—not
                improve hiring and retention.
              </p>
              <p className='our-process__description'>
                The first step to high-performance talent acquisition is to
                understand your true cost per hire and what actions impact it.
                In order to know who to hire, you have to know your company,
                know the role, and know your people.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurProcess;
