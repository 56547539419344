import React from 'react';
import Photo from '../../images/home-image.png';
import LogosComponent from '../LogosComponent';

const HomeComponent = () => {
  return (
    <section className='home-section'>
      <div className='full-container'>
        <div className='row'>
          <div className='col-md-6 home-section__content'>
            <div>
              <p className='home-section__title'>
                Find & keep the talent you need to be <span>successful</span>
              </p>
              <p className='home-section__description'>
                You’ve spent years building your business—not your talent
                acquisition strategy. That’s where TRACTalent comes in. Using a
                people-first approach and drawing on decades of experience in
                hiring and company culture, we ensure you not only find, but
                keep the talent you need to be successful.
              </p>
              <div className='home-logos'>
                <LogosComponent />
              </div>
            </div>
          </div>
          <div className='col-md-6 home-section__image-wrapper'>
            <img className='home-section__image' src={Photo} alt='home' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeComponent;
