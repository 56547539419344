import React from 'react';
import Logo from '../images/TracTalent-logo.svg';
import { Link } from 'react-router-dom';

const Navbar = ({ setModalOpen, modalOpen }) => {
  return (
    <div className='navbar'>
      <div className='navbar-wrapper'>
        <img src={Logo} alt='logo' className='navbar-wrapper-logo' />
        <button
          onClick={() => setModalOpen(!modalOpen)}
          className='navbar-wrapper-button'
        >
          CONTACT US
        </button>
      </div>
    </div>
  );
};

export default Navbar;
