import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Image1 from '../../images/image-1.png';
import Image2 from '../../images/image-2.png';
import Image3 from '../../images/image-3.png';
import Image4 from '../../images/image-4.png';

const CardsComponent = ({ setModalOpen, modalOpen }) => {
  const cards = [
    {
      title: 'Five Fundamentals of Employee Satisfaction',
      button: 'read more',
      image: Image1,
      hover: 'Five Fundamentals of Employee Satisfaction',
      link: 'https://www.linkedin.com/in/jdconwaytalent/overlay/1615259626920/single-media-viewer?type=LINK&profileId=ACoAAAHfFZwB2f59_Qu0hsIf6do72nZoYmJw0Gs&lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base%3B%2FfgQfymgQqi0p2IfKlaisQ%3D%3D',
    },
    {
      title: 'Dissonance Disaster: When Employer Brand & Talent...', //Brand Don’t Align
      button: 'read more',
      image: Image2,
      hover:
        'Dissonance Disaster: When Employer Brand & Talent Brand Don’t Align',
      link: 'https://www.linkedin.com/in/jdconwaytalent/overlay/1635457073507/single-media-viewer?type=LINK&profileId=ACoAAAHfFZwB2f59_Qu0hsIf6do72nZoYmJw0Gs&lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base%3BMkp%2FWIBdQXySnqCep20IHA%3D%3D',
    },
    {
      title: 'Spark Hire: The Growth Recruiting Podcast...',
      button: 'watch now',
      image: Image3,
      hover: 'Spark Hire: The Growth Recruiting Podcast ft. JD Conway',
      link: 'https://www.sparkhire.com/resources/the-speed-to-hire-show?wchannelid=gjeg79kupy&wmediaid=94deti56vj',
    },
    {
      title: 'How BambooHR scaled their Talent Acquisition...', //team to 25 people
      button: 'watch now',
      image: Image4,
      hover: 'How BambooHR scaled their Talent Acquisition team to 25 people',
      link: 'https://www.youtube.com/watch?v=0WdW_e6XBxo',
    },
  ];
  return (
    <div className='cards-section'>
      <div className='cards-section__container container'>
        <div className='row'>
          <div className='col-md-5 d-flex flex-column align-items-start justify-content-center'>
            <p className='cards-section__title'>Getting to know us </p>
            <p className='cards-section__description'>
              Check out these articles and webinars by our Founder JD Conway and
              learn more about our process, philosophy, and passion for Talent
              Acquisition.
            </p>
            <button
              className='cards-section__button'
              onClick={() => setModalOpen(!modalOpen)}
            >
              let’s connect
            </button>
          </div>
          <div className='col-md-7'>
            <div className='cards-section__wrapper'>
              <div className='row'>
                {cards.map((card, i) => (
                  <div className='col-md-6' key={i}>
                    <div className='cards-section__card'>
                      <img
                        src={card.image}
                        alt='card image'
                        className='cards-section__card-image'
                      />
                      <div className='cards-section__card-content'>
                        <p
                          title={card.hover}
                          className='cards-section__card-title'
                        >
                          {card.title}
                        </p>
                        <div className='cards-section__card-button'>
                          <a
                            className='cards-section__card-button-link'
                            href={card.link}
                            target='_blank'
                          >
                            {card.button}
                          </a>
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            color='#CC1570'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardsComponent;
