import React, { useState } from 'react';
import SEO from '../components/seo';
import HomeComponent from '../components/Home/HomeComponent';
import OurProcess from '../components/Home/OurProcess';
import CardsComponent from '../components/Home/CardsComponent';
import FounderComponent from '../components/Home/FounderComponent';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import Contact from './contact';
import Image from '../images/TracTalent-logo.svg';

const Home = () => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div
      style={{
        overflowX: 'hidden',
      }}
    >
      <SEO title={'Home'} image={Image} />
      <Navbar setModalOpen={setModalOpen} modalOpen={modalOpen} />
      {modalOpen && (
        <Contact setModalOpen={setModalOpen} modalOpen={modalOpen} />
      )}

      <HomeComponent />
      <OurProcess />
      <CardsComponent setModalOpen={setModalOpen} modalOpen={modalOpen} />
      <FounderComponent />
      <Footer />
    </div>
  );
};

export default Home;
